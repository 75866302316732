import { library, dom, config } from '@fortawesome/fontawesome-svg-core';

import {
  faCircleChevronDown as falFaCircleChevronDown,
  faCircleChevronRight as falFaCircleChevronRight,
  faSmile as falFaSmile,
  faXmark as falFaXmark,
} from '@fortawesome/pro-light-svg-icons';

import {
  faBriefcase as fasFaBriefcase,
  faChevronDown as fasFaChevronDown,
  faChevronLeft as fasFaChevronLeft,
  faChevronRight as fasFaChevronRight,
  faChevronsDown as fasFaChevronsDown,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasFaCircleInfo,
  faCircleSmall as fasFaCircleSmall,
  faCompress as fasFaCompress,
  faEarthAmericas as fasEarthAmericas,
  faEnvelope as fasFaEnvelope,
  faExpand as fasFaExpand,
  faImage as fasFaImage,
  faLink as fasFaLink,
  faLinkSimple as fasFaLinkSimple,
  faLocationArrow as fasFaLocationArrow,
  faMapMarkerAlt as fasFaMapMarker,
  faMask as fasFaMask,
  faMobileAlt as fasFaMobile,
  faPause as fasFaPause,
  faPen as fasFaPen,
  faPlay as fasFaPlay,
  faPlus as fasFaPlus,
  faQuoteRight as fasQuoteRight,
  faSearch as fasSearch,
  faSpinner as fasFaSpinner,
  faUser as fasFaUser,
  faVolume as fasFaVolume,
  faVolumeSlash as fasFaVolumeSlash,
  faVolumeXmark as fasFaVolumeXmark,
  faWifi as fasFaWifi,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faArrowUpFromBracket as farFaArrowUpFromBracket,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faCircleInfo as farFaCircleInfo,
  faCirclePlay as farFaCirclePlay,
  faClipboard as farClipboard,
  faEarthAmericas as farFaEarthAmericas,
  faEllipsisV as farFaEllipsisV,
  faFeatherPointed as farFeatherPointed,
  faGear as farFaGear,
  faHandWave as faHandWave,
  faHeart as farFaHeart,
  faHouse as farFaHouse,
  faHorizontalRule as farFaHorizontalRule,
  faLongArrowLeft as farFaLongArrowLeft,
  faLongArrowRight as farFaLongArrowRight,
  faSwatchbook as faFarSwatchbook,
  faTableLayout as farFaTableLayout,
  faXmark as farFaXmark,
} from '@fortawesome/pro-regular-svg-icons';

import { faMap as farFaMap } from '@fortawesome/free-regular-svg-icons';

import {
  faFacebook as fabFaFacebook,
  faFacebookSquare as fabFaFacebookSquare,
  faGithub as fabFaGithub,
  faInstagram as fabFaInstagram,
  faLinkedin as fabFaLinkedin,
  faPinterest as fabFaPinterest,
  faSquareXTwitter as fabFaSquareXTwitter,
  faTiktok as fabFaTiktok,
  faTwitter as fabFaTwitter,
  faTwitterSquare as fabFaTwitterSquare,
  faUnsplash as fabFaUnsplash,
  faVimeo as fabFaVimeo,
  faXTwitter as fabFaXTwitter,
  faYoutube as fabFaYoutube,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  fabFaFacebook,
  fabFaFacebookSquare,
  fabFaGithub,
  fabFaInstagram,
  fabFaLinkedin,
  fabFaPinterest,
  fabFaSquareXTwitter,
  fabFaTiktok,
  fabFaTwitter,
  fabFaTwitterSquare,
  fabFaUnsplash,
  fabFaVimeo,
  fabFaXTwitter,
  fabFaYoutube,
  falFaCircleChevronDown,
  falFaCircleChevronRight,
  falFaSmile,
  falFaXmark,
  farChevronLeft,
  farChevronRight,
  farClipboard,
  farFaArrowUpFromBracket,
  farFaCircleInfo,
  farFaCirclePlay,
  farFaEllipsisV,
  farFeatherPointed,
  farFaGear,
  faHandWave,
  farFaEarthAmericas,
  farFaHeart,
  farFaHorizontalRule,
  farFaHouse,
  farFaLongArrowLeft,
  farFaLongArrowRight,
  farFaTableLayout,
  farFaXmark,
  farFaMap,
  fasFaBriefcase,
  fasCircleExclamation,
  fasEarthAmericas,
  fasFaChevronDown,
  fasFaChevronLeft,
  fasFaChevronRight,
  fasFaChevronsDown,
  fasFaCircleInfo,
  fasFaCircleSmall,
  fasFaCompress,
  fasFaEnvelope,
  fasFaExpand,
  fasFaImage,
  fasFaLink,
  fasFaLinkSimple,
  fasFaLocationArrow,
  fasFaMapMarker,
  fasFaMask,
  fasFaMobile,
  fasFaPause,
  fasFaPen,
  fasFaPlay,
  fasFaPlus,
  fasFaSpinner,
  faFarSwatchbook,
  fasFaUser,
  fasFaVolume,
  fasFaVolumeSlash,
  fasFaVolumeXmark,
  fasFaWifi,
  fasQuoteRight,
  fasSearch
);

config.autoA11y = true;
config.keepOriginalSource = false;
config.mutateApproach = 'sync';
config.showMissingIcons = false;

dom.watch();
